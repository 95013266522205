import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, Event } from '@angular/router';
import { APOLLO_USER_GROUPS_KEYS } from '../../models/global';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, startWith, tap } from 'rxjs';

export interface NavItem {
  path: string;
  title: string;
  icon?: string;
  svgIcon?: string;
  class?: string;
  isExternalLink: boolean;
  subMenu? : NavItem[];
  expand?: boolean;
  groups?: APOLLO_USER_GROUPS_KEYS[];
}


@Component({
  selector: 'app-collapsable-menu',
  standalone: true,
  imports: [MatIconModule, RouterLink, NgClass, AsyncPipe],
  templateUrl: './collapsable-menu.component.html',
  styleUrls: ['./collapsable-menu.component.scss']
})
export class CollapsableMenuComponent {
  navItems = input<NavItem[]>();
  clicked = output<boolean>();
  expand = true;
  navItems$ = this.router.events
  .pipe(
    // tap(val => console.log(val)),
    takeUntilDestroyed(this.destroyRef),
    // we only want one NavigationEnd  event to filter through
    filter((event: Event) => event instanceof NavigationEnd),
    startWith(this.router),
    map(() => {
      console.log(this.navItems())
      return this.navItems().map(item => {
        const path = window.location.pathname;
        item.expand = false;
        item.subMenu = item.subMenu.map(subMenu => {
          const match = path.includes(subMenu.path);
          if (match) {
            item.expand = true;
          }
          return {
            ...subMenu,
            class: path.includes(subMenu.path) ? 'bg-accent-100 [&_span]:!font-bold': ''
          }
        })
        return item;
      })
    })

  )

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private destroyRef: DestroyRef,

  ) {
    this.router.events
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      // we only want one NavigationEnd  event to filter through
      filter((event: Event) => event instanceof NavigationEnd),

    ).subscribe();
  }
}
